import { AfterViewInit, ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { TableCellInstance } from '@proman/table/components/table-cell.component';
import { isObject } from '@proman/utils';

@Component({
  selector: 'pm-table-cell-overlay',
  template: `
        <div [proOverlay]="fieldValue" [proOverlayTemplate]="template">
            @if (data) { <fa [name]="config.config.icon || 'search-plus'"></fa> }
        </div>

        <ng-template #productionParameters>
          @if (data) {
            @for (productionParameter of data.parameters; track $index) {
              <pm-parameter-view [parameter]="productionParameter"
                                 [config]="{ label: productionParameter.parameter.name }"></pm-parameter-view>
            }
          }
        </ng-template>

        <ng-template #eventLogMetadata>
          @if (data) {
            @for (item of data.metadata | proKeys; track $index) {
              <div><b>{{ item.key }}</b>: 
                @if (isObject(item.value)) {
                  <br>{{ item.value.before }} &rarr; {{ item.value.after }}
                } @else {
                  {{ item.value }}
                }
              </div>
            }
          }
        </ng-template>
    `
})

export class TableCellOverlayComponent extends TableCellInstance implements OnInit, AfterViewInit {
  @ViewChild('productionParameters') productionParameters: TemplateRef<any>;
  @ViewChild('eventLogMetadata') eventLogMetadata: TemplateRef<any>;
  item: any;
  config: any;
  data: any;
  type: string;
  fieldValue: { type: string; data: any };

  template: TemplateRef<any>;
  templateConfig: { [key: string]: TemplateRef<any> } = {};

  constructor(private cd: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
    this.fieldValue = this.config.getValue(this.item);
    this.data = this.fieldValue.data;
    this.type = this.fieldValue.type;
  }

  ngAfterViewInit() {
    this.templateConfig['productionParameters'] = this.productionParameters;
    this.templateConfig['eventLogMetadata'] = this.eventLogMetadata;
    this.template = this.templateConfig[this.type];

    this.cd.markForCheck();
  }

  protected readonly isObject = isObject;
}
